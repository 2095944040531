input {
  outline: none;
  border: none;
}

input:focus {
  outline: 2px solid #fd8509;
}

.body {
  min-height: 100vh;
}

.bg-first {
  background-color: #fd8509;
}

.bg-second {
  background-color: #fff1d4;
}

.bg-light {
  background-color: #fffaf3;
}

.bg-accent {
  background-color: #ffe0a8;
}

.bg-dark {
  background-color: #441906;
}

.text-first {
  color: #fd8509;
}

.text-dark {
  color: #441906;
}

.text-light {
  color: #fffaf3;
}

.text-accent {
  color: #ffe0a8;
}

.text-inter {
  font-family: "Inter", sans-serif;
}

.text-pop {
  font-family: "Poppins", sans-serif;
}

.text-pjs {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.text-big {
  font-size: 1.2em;
}

.text-small {
  font-size: 0.8em;
}

.text-smaller {
  font-size: 0.6em;
}

.text-smallest {
  font-size: 0.4em;
}

.max-height {
  height: 100%;
}

.navbar-nav,
.mr-auto {
  flex: 1;
  margin: auto !important;
  display: flex;
  justify-content: space-between;
}

.offcanvas-body {
  overflow-y: auto;
  height: 100vh;
}

.floating-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .floating-btn {
    width: 100px;
    height: 100px;
    font-size: 40px;
  }
}
@media (min-width: 992px) {
  .floating-btn {
    width: 70px;
    height: 70px;
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .floating-btn {
    width: 80px;
    height: 80px;
    font-size: 32px;
  }
}
@media (min-width: 1400px) {
  .floating-btn {
    width: 90px;
    height: 90px;
    font-size: 36px;
  }
}

.btn-first {
  font-weight: 600; /* Default font weight */
  color: white; /* Default text color */
  background-color: #fd8509; /* Use defined background color */

  /* Hover state */
  &:hover {
    color: white; /* Maintain white text color on hover */
    background-color: #f57c00; /* 10% darker shade of #a855ac (assuming --bd-violet-bg is #a855ac) */
    border-color: #f57c00; /* Match background color on hover */
  }

  &:focus {
    color: #441906;
    box-shadow: 0 0 0 0.25rem #fd8509;
  }

  &:active {
    color: white;
    background-color: #f57c00;
    border-color: #f57c00;
  }
}

.btn-accent {
  font-weight: 600; /* Default font weight */
  color: #441906; /* Default text color */
  background-color: #ffe0a8; /* Use defined background color */

  /* Hover state */
  &:hover {
    color: #441906; /* Maintain white text color on hover */
    background-color: #e6ca96; /* 10% darker shade of #a855ac (assuming --bd-violet-bg is #a855ac) */
    border-color: #e6ca96; /* Match background color on hover */
  }

  &:focus {
    color: #441906;
    box-shadow: 0 0 0 0.25rem #ffe0a8;
  }

  &:active {
    color: #441906;
    background-color: #ffe0a8;
    border-color: #ffe0a8;
  }
}

.card-img-sz {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
